import React, { Component } from "react";
import GatsbyLink from "../../GatsbyLink";
import "./RelatedPass.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper-bundle.css";
// import "swiper/components/navigation/navigation.scss";
import PostListItem from "../../Blog/PostListItem";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const ThisSlider = ({
  posts,
  siteMetadata,
  dateFormat,
  pathPrefix,
  showAuthor,
}) => {
  return (
    <Swiper
      spaceBetween={44}
      // slidesPerView={2}
      navigation
      loop
      breakpoints={{
        700: {
          slidesPerView: 2,
        },
      }}
    >
      {posts &&
        posts.map(
          (post, slide_count) =>
            post.node.slug !== "gatsby" && (
              <SwiperSlide key={`slide_${slide_count}`}>
                <PostListItem
                  key={slide_count}
                  data={post.node}
                  dateFormat={dateFormat}
                  siteMetadata={siteMetadata}
                  pathPrefix={pathPrefix}
                  showAuthor={showAuthor}
                />
              </SwiperSlide>
            )
        )}
    </Swiper>
  );
};

class ListView extends Component {
  render() {
    const {
      posts,
      siteMetadata,
      pathPrefix,
      showAuthor,
      dateFormat,
    } = this.props;
    return (
      <div className="list-view">
        {posts &&
          posts.map(
            (post, slide_count) =>
              post.node.slug !== "gatsby" && (
                <PostListItem
                  key={slide_count}
                  data={post.node}
                  siteMetadata={siteMetadata}
                  pathPrefix={pathPrefix}
                  showAuthor={showAuthor}
                  dateFormat={dateFormat}
                />
              )
          )}
      </div>
    );
  }
}

export const RelatedPass = (props) => {
  const {
    data,
    title = "Related<br/>Posts",
    listView,
    contentType = "post",
    projectCat = null,
    postCat = null,
    action = null,
    uid = "",
    limit = 6,
  } = props;
  const {
    allWordpressPost,
    allWordpressWpProjects,
    wordpressWpSettings,
    siteSettings,
  } = data;

  const postPass = allWordpressWpProjects
    ? projectCat
      ? allWordpressWpProjects.edges.filter((a) =>
          a?.node.project_category.includes(projectCat)
        )
      : allWordpressWpProjects.edges
    : postCat
    ? allWordpressPost.edges.filter((a) =>
        a?.node?.categories?.find((b) => b?.wordpress_id === parseInt(postCat))
      )
    : allWordpressPost.edges;

  const postFilter = postPass;

  const { showAuthor } = siteSettings.options;
  const { edges: posts } = postPass;
  const blogFeedLink = action
    ? action
    : { url: `/${wordpressWpSettings.blogSlug}/`, title: "View all" };
  const pathPrefix =
    contentType === "project" ? "projects" : wordpressWpSettings.blogSlug;

  if (postFilter.length < 1) {
    return null;
  }

  return (
    <section
      className={`post-feed ${contentType}-list ${listView ? "tiles" : "feed"}`}
    >
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            {title && (
              <span
                className="h3 pass-title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {!listView && (
              <ThisSlider
                posts={postFilter}
                siteMetadata={wordpressWpSettings}
                dateFormat={wordpressWpSettings.date_format}
                pathPrefix={pathPrefix}
                showAuthor={showAuthor}
                uid={uid}
              />
            )}
            {listView && (
              <ListView
                posts={postFilter}
                siteMetadata={wordpressWpSettings}
                dateFormat={wordpressWpSettings.date_format}
                pathPrefix={pathPrefix}
                showAuthor={showAuthor}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
